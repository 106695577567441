import { Accordeon, AccordeonItem } from '@uc-common/b-accordeon';
import { Columns, ColumnItem } from '@uc-common/b-columns';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Icon } from '@uc-common/b-icon';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import { VisualBig } from '@uc-common/b-visual-big';
import { BuzzLogoIdEnum } from '@uc-common/buzz-logo';
import { Testimonials, TestimonialsItem } from '@uc-common/b-testimonials';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { FooterContainer } from '@uc-common/footer/styles/Footer.styles';
import { Footer } from '../../components/Footer';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Link } from '../../components/Link';
import { Tiles, Tile } from '../../components/PotentialBuilderComponents/Tiles';
import { Navbar } from '../../components/Navbar';
import { ClientsBlock } from '../../components/ClientsBlock';
import { GetStartedSection } from '../../components/GetStartedSection';

const StyledFooter = styled(Footer)`
  ${FooterContainer} {
    border-top: none;
  }
`;

export const frontmatter = {
  title: 'Intelligence',
  description:
    'AI tools for media processing automation: object and color recognition, face detection, content-aware cropping, smart compression.',
  noindex: false,
};

const ProductName = ({ text, icon }) => (
  <Box
    sx={{
      display: 'flex',
      px: PaddingEnum.HORIZONTAL_WIDE,
      paddingBottom: '15px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        width: ['38px', '44px'],
        height: ['38px', '44px'],
        marginLeft: '-4px',
      }}
    >
      <Icon id={icon} />
    </Box>
    <Text
      sx={{
        fontWeight: 'normal',
        fontSize: ['18px', '20px', '22px'],
        paddingLeft: ['8px', '10px'],
      }}
    >
      {text}
    </Text>
  </Box>
);

const CustomTile = ({ title, description, illustrationSrc, imgSx }) => (
  <Tile
    title={title}
    description={description}
    illustrationSrc={illustrationSrc}
    backSx={{ backgroundColor: '#A3D8FF' }}
    faceSx={{
      '& > div': {
        alignItems: 'flex-end',
      },
      img: imgSx,
    }}
  />
);

const SnipBlue = styled.span`
  color: #87cdff;
`;
const SnipGray = styled.span`
  color: #6c6c6c;
`;

const Intelligence = ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <Navbar />
      <BLayout header={null} footer={null}>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <ProductName text="Intelligence" icon="product-intelligence-color" />
          <Head
            variant={HeadVariantsEnum.V1}
            title="AI tools for automated media processing"
            description=""
            cta={
              <>
                <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                  Get started
                </Button>
                <Button as={Link} to="/schedule-demo/" type={ButtonTypeEnum.DARK} ghost>
                  Get a demo
                </Button>
              </>
            }
          />
          <Tiles
            sx={{
              gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
              gridAutoRows: ['180px', '226px', '226px', '226px'],
              paddingTop: PaddingEnum.VERTICAL_M,
              px: PaddingEnum.HORIZONTAL_NORMAL,
            }}
          >
            <CustomTile
              title="Object recognition"
              description="Tag images with detected objects."
              illustrationSrc="https://ucarecdn.com/2540e7cc-95bd-414e-ad1c-81c47f716d53/intobjectrecognition.svg"
              imgSx={{
                pb: ['15px', '15px', '15px', '20px'],
                px: ['15px', '15px', '15px', '30px'],
                height: ['127px', 'auto'],
              }}
            />
            <CustomTile
              title="Color recognition"
              description="Find dominant colors in&nbsp;images."
              illustrationSrc="https://ucarecdn.com/6bf66eef-e292-4e5d-825d-ed0818851e01/intcolorrecognition.svg"
              imgSx={{
                pb: ['15px', '15px', '15px', '20px'],
                px: ['15px', '15px', '15px', '20px'],
                height: ['127px', 'auto'],
              }}
            />
            <CustomTile
              title="Smart blur"
              description="Detect and blur faces automatically."
              illustrationSrc="https://ucarecdn.com/4fc79a47-4a26-4f11-bb0e-305cbb886b9a/intsmartblur.svg"
              imgSx={{
                height: ['125px', '105px', '115px', '130px'],
                objectFit: ['contain', 'cover'],
              }}
            />
            <CustomTile
              title="Smart crop"
              description="Crop images to prominent objects."
              illustrationSrc="https://ucarecdn.com/5d9309d7-2612-4885-8730-91ebc64f067b/intsmartcrop.svg"
              imgSx={{
                pb: ['3px', '3px', '3px', '5px'],
                px: ['15px', '15px', '15px', '20px'],
                height: ['130px', 'auto'],
              }}
            />
            <CustomTile
              title="Face detection"
              description="Find areas containing faces in&nbsp;images."
              illustrationSrc="https://ucarecdn.com/92121053-b920-4cfa-99e4-d03c45e1533e/intfacedetection.svg"
              imgSx={{
                px: ['15px', '25px', '40px', '45px'],
                height: ['130px', 'auto'],
              }}
            />
            <CustomTile
              title="Smart compression"
              description="Find the best file size-to-quality ratio without visible artifacts."
              illustrationSrc="https://ucarecdn.com/6c806fee-af0e-41e6-9540-bb3c6b97bedd/intsmartcompression.svg"
              imgSx={{
                height: ['100%', '125px', '135px', '150px'],
                objectFit: 'cover',
              }}
            />
          </Tiles>
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head
            variant={HeadVariantsEnum.V2}
            title="Turn any image into actionable&nbsp;data"
            description=""
            cta={
              <>
                <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                  Integrate now
                </Button>
                <Button
                  as={Link}
                  to="/docs/transformations/image/#image-transformations-list"
                  type={ButtonTypeEnum.DARK}
                  ghost
                >
                  Explore docs
                </Button>
              </>
            }
          />
          <Box
            sx={{
              display: 'grid',
              width: '100%',
              gridTemplateColumns: ['1fr', '1fr 1fr', '2fr 1fr'],
              gridAutoRows: ['246px 324px', '324px', '346px', '366px'],
              gridGap: '8px',
              px: PaddingEnum.HORIZONTAL_NORMAL,
              paddingTop: PaddingEnum.VERTICAL_M,
            }}
          >
            <VisualBig
              src="https://ucarecdn.com/e22e85ad-ce9e-43d5-837d-3bfa1cb370ff/intactionabledata.svg"
              sx={{
                px: '0',
                '& > img': { objectFit: 'cover', height: '100%' },
              }}
            />
            <Box
              sx={{
                backgroundColor: 'black',
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'var(--font-mono)',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#131313',
                  textAlign: 'center',
                  px: '15px',
                  py: '29px',
                  fontSize: '12px',
                  color: '#404040',
                  lineHeight: ['24px', '24px', '26px', '28px'],
                }}
              >
                01
                <br />
                02
                <br />
                03
                <br />
                04
                <br />
                05
                <br />
                06
                <br />
                07
                <br />
                08
                <br />
                09
                <br />
                10
                <br />
                11
                <br />
              </Box>
              <Box
                as="pre"
                sx={{
                  px: '32px',
                  py: '29px',
                  color: '#ffffff',
                  fontSize: ['14px', '14px', '15px', '16px'],
                  width: 'auto',
                  '&, & > code': {
                    fontFamily: 'var(--font-mono)',
                    lineHeight: ['24px', '24px', '26px', '28px'],
                  },
                }}
              >
                <code>
                  <SnipBlue>&quot;main_colors&quot;</SnipBlue>
                  <SnipGray>: [</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipGray>[</SnipGray>246<SnipGray>,</SnipGray> 246
                  <SnipGray>,</SnipGray> 246<SnipGray>],</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipGray>[</SnipGray>163<SnipGray>,</SnipGray> 216
                  <SnipGray>,</SnipGray> 255<SnipGray>],</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipGray>[</SnipGray>252<SnipGray>,</SnipGray> 223
                  <SnipGray>,</SnipGray> 223<SnipGray>]</SnipGray>
                  <br />
                  <SnipGray>],</SnipGray>
                  <br />
                  <SnipBlue>&quot;recognition_info&quot;</SnipBlue>
                  <SnipGray>: &#123;</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipBlue>&quot;Cat&quot;</SnipBlue>
                  <SnipGray>:</SnipGray> 0.94234<SnipGray>,</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipBlue>&quot;Clouds&quot;</SnipBlue>
                  <SnipGray>:</SnipGray> 0.89521<SnipGray>,</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipBlue>&quot;Birds&quot;</SnipBlue>
                  <SnipGray>:</SnipGray> 0.85512<SnipGray>,</SnipGray>
                  <br />
                  &nbsp;&nbsp;<SnipBlue>&quot;Sky&quot;</SnipBlue>
                  <SnipGray>:</SnipGray> 0.63008
                  <br />
                  &#125;
                </code>
              </Box>
            </Box>
          </Box>
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head
            variant={HeadVariantsEnum.V2}
            title="Limitless automation possibilities"
            description=""
          />
          <Accordeon
            sx={{
              paddingTop: PaddingEnum.VERTICAL_M,
              '& > div:last-child': { backgroundColor: 'transparent' },
            }}
          >
            <AccordeonItem
              title="Content sorting"
              description="Extract metadata to organize content and let users search by objects and colors."
              illustrationSrc="https://ucarecdn.com/50f2a03b-694a-4bd9-be19-fae8bfbe46db/2contentsorting.svg"
            />
            <AccordeonItem
              title="Content optimization"
              description="Find the best compression to quality ratio for every image automatically."
              illustrationSrc="https://ucarecdn.com/b92d47dc-f02a-4b93-b057-0675a3a9e3d5/3contentoptimization.svg"
            />
            <AccordeonItem
              title="Content moderation"
              description="Create custom moderation workflows by checking user-uploaded images for certain objects."
              illustrationSrc="https://ucarecdn.com/7e12ce39-1473-49a7-985e-9b0a91bc3182/1contentmoderation.svg"
            />
            <AccordeonItem
              title="Art direction"
              description="Ensure consistent image sizes and proportions with content-aware tools."
              illustrationSrc="https://ucarecdn.com/887a952a-9d83-4dbc-a831-3d8ce9cc1a7b/4artdirection.svg"
            />
            <AccordeonItem
              title="Privacy protection"
              description="Comply with privacy laws using automatic face blur."
              illustrationSrc="https://ucarecdn.com/b0aea2f7-6cd9-441d-9902-2eb7b5c9299f/5privacy.svg"
            />
          </Accordeon>
          <Testimonials sx={{ paddingTop: PaddingEnum.VERTICAL_M }}>
            <TestimonialsItem
              logoId={BuzzLogoIdEnum.CHATRA}
              logoWidths={144}
              customerTitle={
                <>
                  Yakov Karda,
                  <br />
                  CEO & co-founder
                </>
              }
              customerAvatarUuid="2c2ad30d-b5cf-4c08-bcd4-6f088424844d"
            >
              In-house file infrastructure? No thank you. With Uploadcare, we save 40 hours of our
              developers time per week and decrease the total cost of ownership (TCO) on
              infrastructure development. More to ask?
            </TestimonialsItem>
          </Testimonials>
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_L,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V2}
            title="Comes as part of a robust ecosystem"
            description=""
          />
          <VisualBig
            src="https://ucarecdn.com/40fbfff7-c3f4-482e-a035-5a65193eae49/intelligenceinfrastructure.svg"
            sx={{
              paddingTop: PaddingEnum.VERTICAL_M,
            }}
          />
          <Columns
            sx={{
              paddingTop: PaddingEnum.VERTICAL_S,
            }}
          >
            <ColumnItem
              title="Bi-directional CDN"
              description="With 288,000 acceleration nodes under the hood, Uploadcare ensures 4X faster uploads and 8.9X faster delivery."
            />
            <ColumnItem
              title="Powerful API"
              description="The friendly yet powerful API with extensive docs will ensure maximum workflow efficiency."
            />
            <ColumnItem
              title="Advanced data security"
              description="Data encryption, access control, backups and more with the full support of Uploadcare engineers."
            />
          </Columns>
        </Section>
        <GetStartedSection />
      </BLayout>{' '}
      <Flex
        width="100%"
        justifyContent="center"
        backgroundColor="#F6F6F6"
        py={['72px', '82px', '94px', '104px']}
        px={['28px', '97px', '86px', '89px']}
        mb={PaddingEnum.VERTICAL_M}
      >
        <ClientsBlock />
      </Flex>
      <StyledFooter />
    </CommonLayout>
  );
};

export default Intelligence;
